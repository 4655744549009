import React from 'react';
import MesaRegistro from './components/mesa_registro/mesa'
import FormCarnet from './components/mesa_registro/mesa_form_alta';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Routes>
      {/* ============== RUTAS PÚBLICAS ============== */}
      <Route path={`${process.env.PUBLIC_URL}/`} element={<MesaRegistro />}/>
      <Route path={`${process.env.PUBLIC_URL}/mesaregistro`} element={<MesaRegistro />}/>
      <Route path={`${process.env.PUBLIC_URL}/mesaregistro/nuevo`} element={<FormCarnet esMesaRegistro={true} />}/>
      <Route path={`${process.env.PUBLIC_URL}/mesaregistro/editar/:id`} element={<FormCarnet esMesaRegistro={true} esEditar={1} />}/>
    </Routes>
  );
}

export default App;