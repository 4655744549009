import getHistory from 'react-router-global-history';
import './notie.css'
import notie from 'notie'

const produccion = 'http://localhost:3000'
// const produccion = 'https://members.onexpo.mx'

const texto = {
    success: {
        img_exito: `Tu imagen se subió correctamente.`,
        insert_exitoso: 'Los datos se guardaron correctamente.',
        edicion_exitosa: `Tus datos se guardaron correctamente.`,
        eliminar_exitoso: `El registro fue eliminado con éxito.`,
        codigo_exitoso: `Código correcto, ahora introduzca su nueva contraseña.`,
        redirect_paypal: `A continuación se te redireccionará a la pantalla de pago.`,
    },
    warning: {
        contacto_existen: (datos) => `El nombre (${datos}) de contacto ya existe, intente con otro nombre.`,
        df_existen: (datos) => `El alias (${datos}) asignado a estos datos fiscales ya existe, intente con otro alias.`,
        cuenta_existente: (datos) => `El correo ${datos} ya existe, intente registrarse con otro correo.`,
        registro_exitoso: `¡Registro realizado exitosamente!, ya puedes iniciar sesión.`,
        edicion_exitosa: `Tus datos se guardaron correctamente.`,
        datos_vacios: 'Los siguientes campos no pueden estar vacíos.',
        img_vacia: 'El campo de la imagen no puede estar vacío.',
        contra_minima: 'La contraseña debe tener como mínimo 6 caracteres.', 
        contra_vacia: 'Los campos de <strong>contraseña</strong> y <strong>verificar contraseña</strong> no pueden estar vacíos.', 
        contra_no_coincide: 'La contraseña no coincide, vuelve a ingresar los datos de acceso.', 
        contra_no_coincide_menor: 'La contraseña debe de tener por lo menos 6 caracteres.', 
        server_error: 'Hay un problema con el servidor, se solucionará en breve, intenta unos minutos más tarde.', 
        correo_no_valido: (dato) => `Formato de correo no permitido <strong>${dato}</strong>, favor de introducir un correo válido, ejemplo (arturo@miempresa.com.mx).`,
        numero_no_valido: (dato) => `Formato de número no permitido <strong>${dato}</strong>, favor de introducir un número válido, ejemplo (8333457812).`,
        // correo_no_registrado: 'Tu correo electrónico no está registrado, da clic aquí y regístrate.',
        correo_no_registrado: 'Tu correo electrónico no está registrado, da clic en <a href="/registro"><strong>Regístrate aquí</strong></a>.',
        error_paypal: 'Lo sentimos por el momento no fue posible realizar esta solicitud, vuelva intentar más tarde.',
        cancel_paypal: 'Puedes intentar realizar nuevamente tu solicitud o de lo contrario cancelar la petición.',
        terminar_solicitud_membresia: '¡Gracias por tu interés! puedes volver intentarlo más tarde llenando nuevamente tus datos.'
    },
    login: {
        warning_seleccion_codigo: 'Selecciona el correo o teléfono donde enviaremos el código de verificación para poder activar tu cuenta.',
        warning_datos_vacios: 'Los campos de usuario o contraseña no pueden estar vacíos.',
        warning_datos_no_validos: 'Tu usuario o contraseña no son válidos. Verifica nuevamente.',
        warning_codigo_verifica_no_valido: 'El código de verificación es incorrecto, vuelva a intentar.',
        warning_codigo_no_valido: 'El código de activación es incorrecto, vuelva a intentar.',
        warning_codigo_autorizado: 'Este código ya fue activado, no es posible volverlo activar.',
        success_codigo_autorizado: 'Código de activación correcto, a continuación, confirma los datos de contacto para la verificación de tu cuenta.',
        succes_cerrando_session:`Cerrando sesión.`,
        succes_bienvenida: (datos) => `Te damos la bienvenida ${datos} a Members Onexpo.`,
        succes_codigo_verifica: (datos) => `Te envíamos un código de verificación a ${datos}.`,
        succes_reenvio_codigo_verifica: (datos) => `Te reenvíamos un nuevo código de verificación a ${datos}.`,
    },
    url: {
        img_usuario: `${produccion}/img_usuarios`
    }
}

const getForm = (idForm, options = {ignore: [], active: 0, claveEtiquetas: ''}) => {
    if(idForm === '' || idForm === null || idForm === undefined){
        return {status: 0}
    }

    var elemet = document.getElementById(idForm)
    var objectReturn = {status: 0, data: {}, isEmtyCount: 0, isEmtyLabel: {}, isEmtyKeys: {}, isEmtyString: "", documentForm: elemet, idForm}

    if(!elemet){
        return {status: 0, data: {}, isEmtyCount: 0, isEmtyLabel: {}, isEmtyKeys: {}, idForm}
    }

    var form = new FormData(elemet)
    var data = {}
    var ignore = JSON.stringify(options.ignore)
    objectReturn = {
        ...objectReturn,
        formData: form,
        status: 1
    }

    if(options.active){
        return objectReturn
    }

    for(var key of form.keys()){
        if(form.get(key) === ''){
            if(!ignore.includes(key)){
                objectReturn.isEmtyCount += 1
                objectReturn.isEmtyKeys[key] = true
            }           
        }

        let docKey = null
        
        try {
            docKey = elemet.querySelector(`[name="${key}"]`) || elemet.getElementById(key) 
        }catch(err){
            continue
        }
                
        if(!docKey){
            continue
        }              

        var label = docKey.dataset? docKey.dataset['label']: 0
        var placeholder = docKey.placeholder

        objectReturn.isEmtyLabel[key] = label? label: placeholder? placeholder: ''

        data[key] = form.get(key)
    }

    objectReturn.keys = Object.keys(objectReturn.isEmtyKeys ? objectReturn.isEmtyKeys : {})

    objectReturn.keys.map((key) => {
        objectReturn.isEmtyString += objectReturn.isEmtyLabel[key] + ', '
    })
    
    if (objectReturn.isEmtyString.length >= 2) {
        objectReturn.isEmtyString = objectReturn.isEmtyString.substring(0, objectReturn.isEmtyString.length - 2)
    }

    var txtVacios = options.claveEtiquetas? document.querySelectorAll(options.claveEtiquetas): ''

    if(txtVacios){
        for (var values of txtVacios.values()) {
            // console.log('----------', values.className)
            values.style['display'] = 'none'
            Object.keys((objectReturn.isEmtyKeys)).map((key) => {
                // var txtKey = document.querySelector(`.${key}`)
                // console.log('----------', values.className, key, values)
                if(values.className.includes(key)){
                    values.style['display'] = 'block'
                }
            })
        }
        
    }

    function formEvent(e){
        try{
            var idName = ''
            if(e.target.id){
                idName = e.target.id
            }else{
                idName = e.target.name
            }

            if(ignore.includes(idName)){
                return
            }
            
            var txt = {}
            var label = {}
            
            if(e.target.id){
                txt = document.getElementById(idName)
                label = document.querySelector(`.${idName}`)
            }else{
                txt = document.querySelector(`[name="${idName}"]`)
                label = document.querySelector(`.${idName}`)
            }

            if(!txt.value){
                label.style['display'] = 'block'
            }else{
                label.style['display'] = 'none'
            }
        }catch(err){
            return
        }
    }

    elemet.addEventListener('keydown', formEvent)
    elemet.addEventListener('keyup', formEvent)
    elemet.addEventListener('keypress', formEvent)
    elemet.addEventListener('change', formEvent)

    objectReturn.data = data
    return objectReturn
}

const formatNumber = (dato, limit = -1) => {
    let intDato = Number(dato)
    if(!typeof Number(intDato) === 'number'){
        return false
    }

    if(!isNaN(Number(intDato))){
        if(limit === -1){
            return true
        }else if(String(intDato).length <= limit){
            return true
        }else{
            return false
        }
    }else{
        return false
    }
}

const enviarImagenes = (datos, callback1) => {
    let arrFetch = []

    datos.body.map((v, i) => {
        arrFetch.push((callback) => {
            return fetch(`/${datos.ruta}`, {
                method: 'POST',
                body: v,
                ...(datos.fetch?datos.fetch: {}) 
            })
            .then((response) => {
                callback({estado: 1, indice: i + 1, response})
            })
            .catch((err) => {
                callback({estado: 0, indice: i + 1, err})
            })
        })
    })

    let indice = 0, arrImg = []
    let callback = (response) => {
        if(response.indice >= arrFetch.length){
            arrImg.push(response)
            callback1({estado: 1, mensaje: 'Termino', arrImg})
        }else{
            arrImg.push(response)
            arrFetch[response.indice](callback)
        }
    }

    arrFetch[indice](callback)
}


const useFetch =  (objeto, callback) => {
    // const history = useHistory(); ;charset=utf-8

    if(objeto.tipo === 'img'){
        return enviarImagenes(objeto, (response) => {
            return callback(response);
        })
    }

    if(objeto.method.toUpperCase() === 'GET'){
        let parametros = '', body = objeto.body? objeto.body: null
        
        if(body){
            Object.keys(body).map((v, i) => {
                if(i === 0){
                    parametros += `?${v}=${body[v]}`
                }else{
                    parametros += `&${v}=${body[v]}`
                }
            })
        }

        fetch(`/${objeto.ruta}${body?parametros:''}`, {
            method: objeto.method,
            headers:{'Content-Type': 'application/json'}
        })
        .then(async(response) => {
            if(response.status === 200){
                let res = await response.json()
                return callback(res);
            }else if(response.status === 404){
                let res = await response.json()
                if(res.estado){
                    return getHistory().replace('/inicio')
                }else{
                    return getHistory().replace('/login')
                }
            }
        })
        .catch((error) => {
            return callback({ estado: 0, error });
        })
    }else{
        fetch(`/${objeto.ruta}`, {
            method: objeto.method,
            body: JSON.stringify(objeto.body),
            headers:{'Content-Type': 'application/json'}
        })
        .then(async(response) => {
            if(response.status === 200){
                let res = await response.json()
                return callback(res);
            }else if(response.status === 404){
                let res = await response.json()
                if(res.estado){
                    return getHistory().replace('/inicio')
                }else{
                    return getHistory().replace('/login')
                }
            }
        })
        .catch((error) => {
            return callback({ estado: 0, error });
        })
    }
}

const validaCorreo = (value) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(value)
}

const maskNombres = (datos, l = 1) => {
    let d1 = datos.substring(0, l)
    let d2 = datos.substring(l, datos.length)
    let result = d1

    for(let i = 0; i < d2.length; i++){
        result += '*'
    }

    return result
}

const maskNumeros = (datos, l = 1) => {
    let d1 = datos.substring(datos.length - l, datos.length)
    let d2 = datos.substring(l, datos.length)
    let result = ''

    for(let i = 0; i < d2.length; i++){
        result += '*'
    }

    result += d1

    return result
}

const maskCorreos = (datos) => {
    let arroba = datos.split('@')
    let d1 = arroba[0]
    let puntos = arroba[1].split('.')
    let d2 = puntos[0]
    let d3 = '.', result = ''

    for(let i = 1; i <= puntos.length - 1; i++){
        d3 += puntos[i]
    }

    result += maskNombres(d1) + '@'
    result += maskNombres(d2)
    result += d3

    return result
}

const obtenerNumero = (numero, codigo) => {
    let _numero = String(numero)
    // let _codigo = String(codigo)
    return _numero.replace(codigo, '').replace('+', '').replace(/( )/gi, '')
}

// const clearNumberCode2 = (numero, codigo) => {
//     let _numero = String(numero)
//     let _codigo = String(codigo)
//     return Number(_numero.substring(_codigo.length, _numero.length))
// }

const clearNumberCode = (numero, codigo) => {
    let _numero = String(numero)
    let _codigo = String(codigo)
    return (_numero.substring(_codigo.length, _numero.length))
}

const clearNumberCodeStr = (numero, codigo) => {
    let _numero = String(numero)
    let _codigo = String(codigo)
    return (_numero.replace('+', '').replace(/( )/gi, '').replace(/\(/gi, '').replace(/\)/gi, '').substring(_codigo.length, _numero.length))
}

const isNull = (a) => {
    try{
        if(a !== null && a !== undefined && a !== NaN){
            if(Object.keys(a).length){
                return 0
            }else{
                return 1
            }
        } else{
            return 1
        }
    }catch(err){
        return 1
    }
}

const getPaginador = (paginador) => {
    let res = [], k = paginador.p, l = 0, o = paginador.numeros
    
    if(paginador.p + 1 >= paginador.max_mostrar){
        l = paginador.p - Math.floor(paginador.max_mostrar / 2)
        o = paginador.p + Math.ceil(paginador.max_mostrar / 2)
    }

    if(paginador.p  > (paginador.u - paginador.max_mostrar) + 2){
        l = (paginador.ultimo ) - paginador.max_mostrar
        o = paginador.ultimo
    }

    if(!(paginador.ultimo  > paginador.numeros)){
        l = 0
        o = paginador.numeros
    }

    for(let i = l; i < o; i++){
        res.push({ ...paginador, p: i + 1, i: i})
        k++
    }
    
    if(!res.length){
        res.push({p: 1, i: 0})
    }

    return res
}

const urlFormat = (url) => {
    if(url){
        return url.toLowerCase().replace(/(%)/gi, '%25').replace(/(#)/gi, '%23').replace(/(-)/gi, '_').replace(/(\ )/gi, '-')
        .replace(/(\/)/gi, '___').replace(/(á)/gi, 'a').replace(/(é)/gi, 'e')
        .replace(/(í)/gi, 'i').replace(/(ó)/gi, 'o').replace(/(ú)/gi, 'u')
        .replace(/(ñ)/gi, 'n').replace(/(\+)/gi, '__').toLowerCase()
    }else{
        return ''
    }
}

const getPath = (url) => {
    let l = url.split('/')
    return l[l.length - 1]
}

let obtenerUrl = (d) => {
    if(d){
        let arr = d.split('/'), str = ''
        str = arr[arr.length - 1]
        str = str.split('.')[0]
        return str
    }else{
        return ''
    }
}

let obtenerUrlQuery = (url) => {
    if(url){
        let arr = url.split('?')
        let arr2 = arr[1].split('&')
        let res = {}

        for(let i = 0; i < arr2.length; i++){
            let pramas = arr2[i].split('=')
            res[pramas[0]] = pramas[1]
        }

        return res
    }else{
        return {}
    }
}

const validNumber = (dato, num) => {
    if(!isNaN(Number(dato))){
        let str = String(dato) 
        
        if(str.length < num){
            return true
        }else{
            return false
        }
    }else{
        return false
    }
}

const validNumber2 = (dato, num) => {    
    if(!isNaN(Number(dato))){
        let str = String(dato) 
        
        if(str.length < num){
            return dato
        }else{
            return str.replace(/[^0-9]/gi, '').substring(0, num)
        }
    }else{
        return dato.replace(/[^0-9]/gi, '').substring(0, num)
    }
}

const getParamsQueryJson = (url, esUrl) => {
    var arr = url.split('?')? url.split('?'): []
    url = arr[0]
    var strParams = arr[1]
    var arrParams = strParams? strParams.split('&'): []
    var _json = {}
    if(esUrl){
        _json['url'] = url
    }
    arrParams.map((v) => {
        var arrItem = v.split('=')
        _json[arrItem[0]] = arrItem[1]
    })
    return _json
}

const getParamsId = () => {
    var arr = window.location.pathname.split('/')
    return arr[arr.length - 1]
}

export default {
    getParamsId,
    getParamsQueryJson,
    validNumber,
    validNumber2,
    obtenerUrlQuery,
    texto,
    notie,
    getForm,
    formatNumber,
    useFetch,
    validaCorreo,
    getHistory,
    maskNombres,
    maskCorreos,
    maskNumeros,
    obtenerNumero,
    enviarImagenes,
    isNull,
    getPaginador,
    urlFormat,
    getPath,
    obtenerUrl,
    clearNumberCode,
    clearNumberCodeStr
}