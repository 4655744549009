// import IntlTelInput from 'react-intl-tel-input';
import React from "react";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import tools from './tools/tools'

const Default = (props) => {
    let {
        placeholder, 
        onChange,
        value,
        // country,
        id,
        name,
        iso,
        code,
        label,
        className
    } = props
    return <PhoneInput
        country={iso? iso: 'mx'}
        value={value !== null && value !== undefined && value !== ''? `${code? code: '+52'}${value}` : '+52'}
        placeholder={placeholder || '+52 ### #### ###'}
        inputStyle={{width: '100%'}}
        inputClass={className? className: ''}
        // autoComplete="off"
        // autocompleteSearch={false}
        // enableAreaCodeStretch={false}
        // enableSearch={false}
        // enableAreaCodes={false}
        countryCodeEditable={false}
        inputProps={{
            value: value !== null && value !== undefined && value !== ''? `${code? '+'+code: '+52'}${value}` : code? '+'+code: '+52',
            required: true,
            autoFocus: false,
            id: id || '0',
            name: name? name: (id || '0'),
            'data-label': label? label: ''
        }}
        onChange={(value, datos, e) => {            
            let response = {
                number: tools.clearNumberCode(value, datos.dialCode), 
                iso: datos.countryCode,
                code: datos.dialCode,
                value,
                params: datos,
                e
            }

            onChange(response)
        }}
    />
}

// console.log(datos)
// let _v = String(value)
// let _iso = (iso)? String(iso): 'mx'
// let _code = (code)? String(code): '52'
// let _varr = _v.split('')
// let _carr = _code.split('')
// _varr = _varr.find((v) => {
//     return !_carr.includes(v)
// })
// value = _varr

export default Default