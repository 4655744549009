export const formatoDinero = (valor) => {
  valor = valor ? parseFloat(valor).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0.00';
  return valor;
}

export const groupArrayByKey = (xs, key, param) => {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || {key: x[param], array: []}).array.push(x);
      return rv;
    }, {});
}