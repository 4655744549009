
import React, { Fragment, useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import MySelect from '../common/select'
// import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import tools from "../../componentes/tools/tools";
// import FormEditarCarnet from './mesa_form_editar'
import Select from '../../componentes/select'

const Default = ({
    modal,
    setModal,
    setRefresh,
    selects
}) => {
    let { catalogos = [], listTipoCarnet = {}, idRegistro = 0 } = modal.datos
    const [selectCatalogo, setSelectCatalogo] = useState([])
    const [datos, setDatos] = useState({})
    // tools.getForm('formCarnetNuevo', { active: 1})
    const toggle = () => {
        if (setModal) {
            setModal((s) => ({...s, estado: !s.estado}))
        }
    }

    const onClickAgregar = () => {
        let carnetsIgnore = ['txtEsAnfitrion', 'listGenero', 'txtWhatsapp', 'txtCorreo', 'listGeneroSeg', 'txtWhatsappSeg', 'txtCorreoSeg']
        let formCarnetNuevo = tools.getForm('formCarnetNuevo', {ignore: carnetsIgnore})

        if(formCarnetNuevo.isEmtyCount){
            return tools.notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Faltan los siguientes campos (${formCarnetNuevo.isEmtyString})`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        fetch('/rt_mesaregistro_nuevo_carnet', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                idRc: idRegistro,
                listTipoCarnet: selects.listTipoCarnet.clave,
                formCarnets: [formCarnetNuevo.data]
            })
        })
        .then((response) => response.json())
        .then((response) => {
            setRefresh((s) => !s)
            toggle()
            tools.notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Carnet registrado correctamente.`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
        .catch((err) => {
            return tools.notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Detalle en el servidor, vuelva a intentar`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    useEffect(() => {
        let isS = true
        if (!datos.listTipoHospedaje) {
            return
        }
        setSelectCatalogo(catalogos.catHotel.filter((v) => datos.listTipoHospedaje.split('-')[0] == v.TIPO_HHB))
        return () => isS = false
    }, [datos.listTipoHospedaje])

    return (
        <Fragment>
            <Modal
                className={`modal-body`}
                isOpen={modal.estado}
                toggle={toggle}
                centered={true}
                size={'lg'}
                style={{ maxWidth: '60em', width: '70%', padding: 0 }}
            >
                <ModalHeader toggle={toggle}><div style={{ fontSize: 20 }}>Nuevo Carnet</div></ModalHeader>
                <ModalBody>
                    <form className="col-md-12" id={`formCarnetNuevo`}>
                        <div className="row">
                            <div className="col-12 m-0 p-0">
                                {/* <div className="card"> */}
                                <div className="carnet-body">
                                    {selects.listTipoCarnet.clave == 1?
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label
                                                    style={{ fontWeight: 600 }}
                                                    htmlFor="pcorreo"
                                                >
                                                    Tipo de carnet<span className="text-danger">*</span>
                                                </label>
                                                <Select
                                                    className="form-control form-control-md border border-dark"
                                                    // tipoValue={1}
                                                    data-label="Hospedaje"
                                                    placeholder="Seleccionar hospedaje"
                                                    id={`listTipoHospedaje`}
                                                    name="listTipoHospedaje"
                                                    datos={catalogos.catTipoHospedaje}
                                                    value={datos.listTipoHospedaje ? datos.listTipoHospedaje : ''}
                                                    onChange={({ item }) => {
                                                        setDatos((s) => ({ ...s, listTipoHospedaje: item.clave }))
                                                    }}
                                                />
                                                <span className={`text-danger txtVaciosItem listTipoHospedaje`} style={{ display: 'none' }}>Falta seleccionar Tipo de hospedaje</span>
                                            </div>
                                        </div>
                                        {datos.listTipoHospedaje == '1-1' || datos.listTipoHospedaje == '2-1' ?
                                            <Fragment>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label
                                                        style={{ fontWeight: 600 }}
                                                        htmlFor="pcorreo"
                                                    >
                                                        Selecciona un hotel<span className="text-danger">*</span>
                                                    </label>
                                                    <Select
                                                        className="form-control form-control-md border border-dark"
                                                        // tipoValue={1}
                                                        data-label="Hotel"
                                                        placeholder="Seleccionar hotel"
                                                        id={`listHotel`}
                                                        name="listHotel"
                                                        // datos={catalogos.catHotel}
                                                        datos={selectCatalogo}
                                                        value={datos.listHotel ? datos.listHotel : ''}
                                                        onChange={({ item }) => {
                                                            setDatos((s) => ({ ...s, listHotel: item.clave }))
                                                        }}
                                                    />
                                                    <span className={`text-danger txtVaciosItem listHotel`} style={{ display: 'none' }}>Falta seleccionar Hotel</span>
                                                </div> 
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label
                                                        style={{ fontWeight: 600 }}
                                                        htmlFor="Camas"
                                                    >
                                                        Camas<span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control border border-dark"
                                                        type="number"
                                                        id='txtCamas'
                                                        name="txtCamas"
                                                        placeholder="Camas"
                                                        defaultValue={2}
                                                        value={datos.txtCamas}
                                                        onChange={(e) => {
                                                            e.persist()
                                                            setDatos((s) => ({ ...s, txtCamas: e.target.value }))
                                                        }}
                                                    />
                                                    <span className={`text-danger txtVaciosItem txtCamas`} style={{ display: 'none' }}>Falta completar (Camas)</span>
                                                </div>
                                            </div>
                                            </Fragment>
                                        : ''}
                                    </div>
                                    :''}
                                    <div className="row">
                                        <div className="col-12 mt-4 mb-2">
                                            <h5>Asistente 1</h5>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label
                                                    style={{ fontWeight: 600 }}
                                                    htmlFor="pnombre"
                                                >
                                                    Nombre(s)<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    className="form-control border border-dark"
                                                    type="text"
                                                    id={`txtNombre`}
                                                    name="txtNombre"
                                                    placeholder="Nombre(s)"
                                                    value={datos.txtNombre ? datos.txtNombre : ''}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        setDatos((s) => ({ ...s, txtNombre: e.target.value }))
                                                    }}
                                                />
                                                <span className={`text-danger txtVaciosItem txtNombre`} style={{ display: 'none' }}>Falta completar (Nombre(s))</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label
                                                    style={{ fontWeight: 600 }}
                                                    htmlFor="papellidos"
                                                >
                                                    Apellidos<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    className="form-control border border-dark"
                                                    id={`txtApellidos`}
                                                    name="txtApellidos"
                                                    type="text"
                                                    placeholder="Apellidos"
                                                    value={datos.txtApellidos ? datos.txtApellidos : ''}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        setDatos((s) => ({ ...s, txtApellidos: e.target.value }))
                                                    }}
                                                />
                                                <span className={`text-danger txtVaciosItem txtApellidos`} style={{ display: 'none' }}>Falta completar Apellidos</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label
                                                    style={{ fontWeight: 600 }}
                                                    htmlFor="pcorreo"
                                                >
                                                    Correo Electrónico
                                                </label>
                                                <input
                                                    className="form-control border border-dark"
                                                    id={`txtCorreo`}
                                                    name="txtCorreo"
                                                    type="text"
                                                    // onBlur={onClickBuscarCorreo}
                                                    placeholder="Correo Electrónico"
                                                    value={datos.txtCorreo ? datos.txtCorreo : ''}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        setDatos((s) => ({ ...s, txtCorreo: e.target.value }))
                                                    }}
                                                />
                                                <span className={`text-danger txtVaciosItem txtCorreo`} style={{ display: 'none' }}>Falta completar Correo Electrónico</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label
                                                    style={{ fontWeight: 600 }}
                                                    htmlFor="pcorreo"
                                                >
                                                    Género
                                                </label>
                                                <Select
                                                    className="form-control form-control-md border border-dark"
                                                    // tipoValue={1}
                                                    data-label="Género"
                                                    placeholder="Seleccionar Género"
                                                    id={`listGenero`}
                                                    name="listGenero"
                                                    datos={catalogos.catGenero}
                                                    value={datos.listGenero ? datos.listGenero : ''}
                                                    onChange={({ item }) => {
                                                        setDatos((s) => ({ ...s, listGenero: item.clave }))
                                                    }}
                                                />
                                                <span className={`text-danger txtVaciosItem listGenero`} style={{ display: 'none' }}>Falta completar Género</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label
                                                    style={{ fontWeight: 600 }}
                                                    htmlFor="pcorreo"
                                                >
                                                    Whatsapp
                                                </label>
                                                <div className="input-group mb-2">
                                                    <div className="input-group-prepend border">
                                                        <div className="input-group-text"><i className="fa fa-whatsapp" aria-hidden="true"></i></div>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control border border-dark"
                                                        id={`txtWhatsapp`}
                                                        name="txtWhatsapp"
                                                        placeholder="Whatsapp"
                                                        value={datos.txtWhatsapp ? datos.txtWhatsapp : ''}
                                                        onChange={(e) => {
                                                            e.persist()
                                                            setDatos((s) => ({ ...s, txtWhatsapp: e.target.value }))
                                                        }}
                                                    />
                                                </div>
                                                <span className={`text-danger txtVaciosItem txtWhatsapp`} style={{ display: 'none' }}>Falta completar Whatsapp</span>
                                                {/* <PhoneInput
                                            name='txtWhatsapp'
                                            className={'border border-dark'}
                                            value={datos.txtWhatsapp}
                                            code={datos.txtWhatsappCode}
                                            iso={datos.txtWhatsappPais}
                                            label='Whatsapp'
                                            onChange={(datos) => {
                                                setDatos((s) => ({
                                                    ...s,
                                                    txtWhatsapp: datos.number,
                                                    txtWhatsappCode: datos.code,
                                                    txtWhatsappPais: datos.iso,
                                                }));
                                            }}
                                        /> */}
                                            </div>
                                        </div>
                                        {(listTipoCarnet.clave == '3') ?
                                            <Fragment>
                                                {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label
                                                        style={{ fontWeight: 600 }}
                                                        htmlFor="Puesto"
                                                    >
                                                        Puesto<span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control border border-dark"
                                                        id={`txtPuesto${indice + 1}`}
                                                        name="txtPuesto"
                                                        type="text"
                                                        placeholder="Puesto"
                                                        value={datos.txtPuesto ? datos.txtPuesto : ''}
                                                        onChange={(e) => {
                                                            e.persist()
                                                            setDatos((s) => ({ ...s, txtPuesto: e.target.value }))
                                                        }}
                                                    />
                                                    <span className={`text-danger txtVaciosItem${indice + 1} txtPuesto${indice + 1}`} style={{ display: 'none' }}>Falta completar Puesto</span>
                                                </div>
                                            </div> */}
                                                {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input border-dark"
                                                            id={`txtEsAnfitrion${indice + 1}`}
                                                            name="txtEsAnfitrion"
                                                            type="checkbox"
                                                            placeholder="Es Anfitrion"
                                                            checked={datos.txtEsAnfitrion ? true : false}
                                                            // value={datos.txtEsAnfitrion? 1: 0}
                                                            // {...(datos.txtEsAnfitrion? checked: '')}
                                                            onChange={(e) => {
                                                                e.persist()
                                                                setDatos((s) => ({ ...s, txtEsAnfitrion: !s.txtEsAnfitrion }))
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="flexCheckDefault" style={{ fontWeight: 600 }}>
                                                            Es Anfitrion<span className="text-danger">*</span>
                                                        </label>
                                                        <span className={`text-danger txtVaciosItem${indice + 1} txtEsAnfitrion${indice + 1}`} style={{ display: 'none' }}>Falta completar Anfitrion</span>
                                                    </div>
                                                </div>
                                            </div> */}
                                            </Fragment>
                                            : ''}
                                    </div>
                                    {datos.listTipoHospedaje == '2-0' || datos.listTipoHospedaje == '2-1' ?
                                        <Fragment>
                                            <div className="row">
                                                <div className="col-12 mt-4 mb-2">
                                                    <h5>Asistente 2</h5>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label
                                                            style={{ fontWeight: 600 }}
                                                            htmlFor="pnombre"
                                                        >
                                                            Nombre(s)<span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control border border-dark"
                                                            type="text"
                                                            id={`txtNombreSeg`}
                                                            name="txtNombreSeg"
                                                            placeholder="Nombre(s)"
                                                            value={datos.txtNombreSeg ? datos.txtNombreSeg : ''}
                                                            onChange={(e) => {
                                                                e.persist()
                                                                setDatos((s) => ({ ...s, txtNombreSeg: e.target.value }))
                                                            }}
                                                        />
                                                        <span className={`text-danger txtVaciosItem txtNombreSeg`} style={{ display: 'none' }}>Falta completar (Nombre(s))</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label
                                                            style={{ fontWeight: 600 }}
                                                            htmlFor="papellidos"
                                                        >
                                                            Apellidos<span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control border border-dark"
                                                            id={`txtApellidosSeg`}
                                                            name="txtApellidosSeg"
                                                            type="text"
                                                            placeholder="Apellidos"
                                                            value={datos.txtApellidosSeg ? datos.txtApellidosSeg : ''}
                                                            onChange={(e) => {
                                                                e.persist()
                                                                setDatos((s) => ({ ...s, txtApellidosSeg: e.target.value }))
                                                            }}
                                                        />
                                                        <span className={`text-danger txtVaciosItem txtApellidosSeg`} style={{ display: 'none' }}>Falta completar Apellidos</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label
                                                            style={{ fontWeight: 600 }}
                                                            htmlFor="pcorreo"
                                                        >
                                                            Correo Electrónico
                                                        </label>
                                                        <input
                                                            className="form-control border border-dark"
                                                            id={`txtCorreoSeg`}
                                                            name="txtCorreoSeg"
                                                            type="text"
                                                            // onBlur={onClickBuscarCorreo}
                                                            placeholder="Correo Electrónico"
                                                            value={datos.txtCorreoSeg ? datos.txtCorreoSeg : ''}
                                                            onChange={(e) => {
                                                                e.persist()
                                                                setDatos((s) => ({ ...s, txtCorreoSeg: e.target.value }))
                                                            }}
                                                        />
                                                        <span className={`text-danger txtVaciosItem txtCorreoSeg`} style={{ display: 'none' }}>Falta completar Correo Electrónico</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label
                                                            style={{ fontWeight: 600 }}
                                                            htmlFor="pcorreo"
                                                        >
                                                            Género
                                                        </label>
                                                        <Select
                                                            className="form-control form-control-md border border-dark"
                                                            // tipoValue={1}
                                                            data-label="Género"
                                                            placeholder="Seleccionar Género"
                                                            id={`listGeneroSeg`}
                                                            name="listGeneroSeg"
                                                            datos={catalogos.catGenero}
                                                            value={datos.listGeneroSeg ? datos.listGeneroSeg : ''}
                                                            onChange={({ item }) => {
                                                                setDatos((s) => ({ ...s, listGeneroSeg: item.clave }))
                                                            }}
                                                        />
                                                        <span className={`text-danger txtVaciosItem listGeneroSeg`} style={{ display: 'none' }}>Falta completar Género</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label
                                                            style={{ fontWeight: 600 }}
                                                            htmlFor="pcorreo"
                                                        >
                                                            Whatsapp
                                                        </label>
                                                        <div className="input-group mb-2">
                                                            <div className="input-group-prepend border">
                                                                <div className="input-group-text"><i className="fa fa-whatsapp" aria-hidden="true"></i></div>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className="form-control border border-dark"
                                                                id={`txtWhatsappSeg`}
                                                                name="txtWhatsappSeg"
                                                                placeholder="Whatsapp"
                                                                value={datos.txtWhatsappSeg ? datos.txtWhatsappSeg : ''}
                                                                onChange={(e) => {
                                                                    e.persist()
                                                                    setDatos((s) => ({ ...s, txtWhatsappSeg: e.target.value }))
                                                                }}
                                                            />
                                                        </div>
                                                        <span className={`text-danger txtVaciosItem txtWhatsappSeg`} style={{ display: 'none' }}>Falta completar Whatsapp</span>
                                                    </div>
                                                </div>
                                                {(listTipoCarnet.clave == '3') ?
                                                    <Fragment>
                                                        {/* <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label
                                                                style={{ fontWeight: 600 }}
                                                                htmlFor="Puesto"
                                                            >
                                                                Puesto
                                                            </label>
                                                            <input
                                                                className="form-control border border-dark"
                                                                id={`txtPuestoSeg${indice + 1}`}
                                                                name="txtPuestoSeg"
                                                                type="text"
                                                                placeholder="Puesto"
                                                                value={datos.txtPuestoSeg ? datos.txtPuestoSeg : ''}
                                                                onChange={(e) => {
                                                                    e.persist()
                                                                    setDatos((s) => ({ ...s, txtPuestoSeg: e.target.value }))
                                                                }}
                                                            />
                                                            <span className={`text-danger txtVaciosItem${indice + 1} txtPuestoSeg${indice + 1}`} style={{ display: 'none' }}>Falta completar Puesto</span>
                                                        </div>
                                                    </div> */}
                                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input border-dark"
                                                        id={`txtEsAnfitrion${indice + 1}`} 
                                                        name="txtEsAnfitrion"
                                                        type="checkbox"
                                                        placeholder="Es Anfitrion"
                                                        checked={datos.txtEsAnfitrion? true: false}
                                                        // value={datos.txtEsAnfitrion? 1: 0}
                                                        // {...(datos.txtEsAnfitrion? checked: '')}
                                                        onChange={(e) => {
                                                            e.persist()
                                                            setDatos((s) => ({ ...s, txtEsAnfitrion: !s.txtEsAnfitrion }))
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault"  style={{ fontWeight: 600 }}>
                                                        Es Anfitrion<span className="text-danger">*</span>
                                                    </label>
                                                    <span className={`text-danger txtVaciosItem${indice + 1} txtEsAnfitrion${indice + 1}`} style={{display: 'none'}}>Falta completar Anfitrion</span>  
                                                </div>
                                            </div>
                                        </div> */}
                                                    </Fragment>
                                                    : ''}
                                            </div>
                                        </Fragment>
                                        : ''}
                                    {/* {datos.listTipoHospedaje == '1-1' || datos.listTipoHospedaje == '2-1' ?
                                        <Fragment>
                                            <div className="row mt-5">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label
                                                            style={{ fontWeight: 600 }}
                                                            htmlFor="pnombre"
                                                        >
                                                            Fecha de entrada<span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control border border-dark"
                                                            type="date"
                                                            id={`txtFechaInicio`}
                                                            name="txtFechaInicio"
                                                            placeholder="Nombre(s)"
                                                            value={datos.txtFechaInicio ? datos.txtFechaInicio : ''}
                                                            onChange={(e) => {
                                                                e.persist()
                                                                setDatos((s) => ({ ...s, txtFechaInicio: e.target.value }))
                                                            }}
                                                        />
                                                        <span className={`text-danger txtVaciosItem txtFechaInicio`} style={{ display: 'none' }}>Falta completar (Nombre(s))</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label
                                                            style={{ fontWeight: 600 }}
                                                            htmlFor="pnombre"
                                                        >
                                                            Fecha de salida<span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control border border-dark"
                                                            type="date"
                                                            id={`txtFechaFin`}
                                                            name="txtFechaFin"
                                                            placeholder="Nombre(s)"
                                                            value={datos.txtFechaFin ? datos.txtFechaFin : ''}
                                                            onChange={(e) => {
                                                                e.persist()
                                                                setDatos((s) => ({ ...s, txtFechaFin: e.target.value }))
                                                            }}
                                                        />
                                                        <span className={`text-danger txtVaciosItem txtFechaFin`} style={{ display: 'none' }}>Falta completar (Nombre(s))</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                        : ''} */}
                                </div>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => onClickAgregar()}
                    >Guardar</Button>
                    <Button
                        color="danger"
                        onClick={toggle}
                    >Cerrar</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default Default