import React, { Fragment, useState, useEffect } from "react";
import tools from "../../componentes/tools/tools";
import Select from '../../componentes/select'

const ItemCarnet = ({
    indice,
    onClickEliminarCarnet,
    catalogos,
    carnet,
    listTipoCarnet,
    carnets,
    setDatosEvento,
    esEditar,
    onClickEditarCarnet
}) => {
    const [datos, setDatos] = useState(carnet)
    const [selectCatalogo, setSelectCatalogo] = useState([])

    useEffect(() => {
        let isS = true

        if (isS) {
            setDatos(carnet)
        }

        return () => isS = false
    }, [carnet])

    useEffect(() => {
        if (!datos.listTipoHospedaje) {
            return
        }

        let formCarnets = carnets.map((s, i) => tools.getForm(`formCarnet${i + 1}`)).map((v) => v.data)

        let numCarnetInd = formCarnets.filter((v) => v.listTipoHospedaje == '1-0' || v.listTipoHospedaje == '1-1').length
        let numCarnetDoble = formCarnets.filter((v) => v.listTipoHospedaje == '2-0' || v.listTipoHospedaje == '2-1').length

        setSelectCatalogo(catalogos.catHotel.filter((v) => datos.listTipoHospedaje.split('-')[0] == v.TIPO_HHB))

        setDatosEvento((s) => ({
            ...s,
            numCarnetInd: numCarnetInd,
            numCarnetDoble: numCarnetDoble,
            totalAsistentes: numCarnetInd + (numCarnetDoble * 2)
        }))
    }, [datos.listTipoHospedaje])

    return <form className="col-md-12" id={`formCarnet${indice + 1}`} key={`formCarnet${indice + 1}`} data-id={esEditar?datos.id:''}>
        <div className="row">
            <div className="col-12 m-0 p-0">
                <div className="card">
                    <div className="carnet-body">
                        <div
                            className="d-flex flex-wrap mt-4"
                            style={{ justifyContent: "space-between" }}
                        >
                            <h5>Carnet {indice + 1}</h5>

                            <button type="button" className="btn btn-danger mb-4 pl-3 pr-3 pb-0 pt-0"
                                onClick={() => onClickEliminarCarnet(indice, carnet)}
                            >
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </button>
                        </div>
                        {listTipoCarnet.clave == 1?
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label
                                        style={{ fontWeight: 600 }}
                                        htmlFor="pcorreo"
                                    >
                                        Tipo de carnet<span className="text-danger">*</span>
                                    </label>
                                    <Select
                                        className="form-control form-control-md border border-dark"
                                        // tipoValue={1}
                                        data-label="Hospedaje"
                                        placeholder="Seleccionar hospedaje"
                                        id={`listTipoHospedaje${indice + 1}`}
                                        name="listTipoHospedaje"
                                        datos={catalogos.catTipoHospedaje}
                                        value={datos.listTipoHospedaje ? datos.listTipoHospedaje : ''}
                                        onChange={({ item }) => {
                                            setDatos((s) => ({ ...s, listTipoHospedaje: item.clave }))
                                        }}
                                    />
                                    <span className={`text-danger txtVaciosItem${indice + 1} listTipoHospedaje${indice + 1}`} style={{ display: 'none' }}>Falta seleccionar Tipo de hospedaje</span>
                                </div>
                            </div>
                            {datos.listTipoHospedaje == '1-1' || datos.listTipoHospedaje == '2-1' ?
                            <Fragment>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label
                                            style={{ fontWeight: 600 }}
                                            htmlFor="pcorreo"
                                        >
                                            Selecciona un hotel<span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            className="form-control form-control-md border border-dark"
                                            // tipoValue={1}
                                            data-label="Hotel"
                                            placeholder="Seleccionar hotel"
                                            id={`listHotel${indice + 1}`}
                                            name="listHotel"
                                            datos={selectCatalogo}
                                            value={datos.listHotel ? datos.listHotel : ''}
                                            onChange={({ item }) => {
                                                setDatos((s) => ({ ...s, listHotel: item.clave }))
                                            }}
                                        />
                                        <span className={`text-danger txtVaciosItem${indice + 1} listHotel${indice + 1}`} style={{ display: 'none' }}>Falta seleccionar Hotel</span>
                                    </div> 
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label
                                            style={{ fontWeight: 600 }}
                                            htmlFor="pnombre"
                                        >
                                            Camas<span className="text-danger">*</span>
                                        </label>
                                        <input
                                            className="form-control border border-dark"
                                            type="number"
                                            id={`txtCamas${indice + 1}`}
                                            name="txtCamas"
                                            placeholder="Camas"
                                            defaultValue={2}
                                            value={datos.txtCamas}
                                            onChange={(e) => {
                                                e.persist()
                                                setDatos((s) => ({ ...s, txtCamas: e.target.value }))
                                            }}
                                        />
                                        <span className={`text-danger txtVaciosItem${indice + 1} txtCamas${indice + 1}`} style={{ display: 'none' }}>Falta completar (Camas)</span>
                                    </div>
                                </div>
                            </Fragment>
                            : ''}
                        </div>
                        :''}
                        <div className="row">
                            <div className="col-12 mt-4 mb-2">
                                <h5>Asistente 1</h5>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label
                                        style={{ fontWeight: 600 }}
                                        htmlFor="pnombre"
                                    >
                                        Nombre(s)<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control border border-dark"
                                        type="text"
                                        id={`txtNombre${indice + 1}`}
                                        name="txtNombre"
                                        placeholder="Nombre(s)"
                                        value={datos.txtNombre ? datos.txtNombre : ''}
                                        onChange={(e) => {
                                            e.persist()
                                            setDatos((s) => ({ ...s, txtNombre: e.target.value }))
                                        }}
                                    />
                                    <span className={`text-danger txtVaciosItem${indice + 1} txtNombre${indice + 1}`} style={{ display: 'none' }}>Falta completar (Nombre(s))</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label
                                        style={{ fontWeight: 600 }}
                                        htmlFor="papellidos"
                                    >
                                        Apellidos<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control border border-dark"
                                        id={`txtApellidos${indice + 1}`}
                                        name="txtApellidos"
                                        type="text"
                                        placeholder="Apellidos"
                                        value={datos.txtApellidos ? datos.txtApellidos : ''}
                                        onChange={(e) => {
                                            e.persist()
                                            setDatos((s) => ({ ...s, txtApellidos: e.target.value }))
                                        }}
                                    />
                                    <span className={`text-danger txtVaciosItem${indice + 1} txtApellidos${indice + 1}`} style={{ display: 'none' }}>Falta completar Apellidos</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label
                                        style={{ fontWeight: 600 }}
                                        htmlFor="pcorreo"
                                    >
                                        Correo Electrónico
                                    </label>
                                    <input
                                        className="form-control border border-dark"
                                        id={`txtCorreo${indice + 1}`}
                                        name="txtCorreo"
                                        type="text"
                                        // onBlur={onClickBuscarCorreo}
                                        placeholder="Correo Electrónico"
                                        value={datos.txtCorreo ? datos.txtCorreo : ''}
                                        onChange={(e) => {
                                            e.persist()
                                            setDatos((s) => ({ ...s, txtCorreo: e.target.value }))
                                        }}
                                    />
                                    <span className={`text-danger txtVaciosItem${indice + 1} txtCorreo${indice + 1}`} style={{ display: 'none' }}>Falta completar Correo Electrónico</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label
                                        style={{ fontWeight: 600 }}
                                        htmlFor="pcorreo"
                                    >
                                        Género
                                    </label>
                                    <Select
                                        className="form-control form-control-md border border-dark"
                                        // tipoValue={1}
                                        data-label="Género"
                                        placeholder="Seleccionar Género"
                                        id={`listGenero${indice + 1}`}
                                        name="listGenero"
                                        datos={catalogos.catGenero}
                                        value={datos.listGenero ? datos.listGenero : ''}
                                        onChange={({ item }) => {
                                            setDatos((s) => ({ ...s, listGenero: item.clave }))
                                        }}
                                    />
                                    <span className={`text-danger txtVaciosItem${indice + 1} listGenero${indice + 1}`} style={{ display: 'none' }}>Falta completar Género</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label
                                        style={{ fontWeight: 600 }}
                                        htmlFor="pcorreo"
                                    >
                                        Whatsapp
                                    </label>
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend border">
                                            <div className="input-group-text"><i className="fa fa-whatsapp" aria-hidden="true"></i></div>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control border border-dark"
                                            id={`txtWhatsapp${indice + 1}`}
                                            name="txtWhatsapp"
                                            placeholder="Whatsapp"
                                            value={datos.txtWhatsapp ? datos.txtWhatsapp : ''}
                                            onChange={(e) => {
                                                e.persist()
                                                setDatos((s) => ({ ...s, txtWhatsapp: e.target.value }))
                                            }}
                                        />
                                    </div>
                                    <span className={`text-danger txtVaciosItem${indice + 1} txtWhatsapp${indice + 1}`} style={{ display: 'none' }}>Falta completar Whatsapp</span>
                                    {/* <PhoneInput
                                    name='txtWhatsapp'
                                    className={'border border-dark'}
                                    value={datos.txtWhatsapp}
                                    code={datos.txtWhatsappCode}
                                    iso={datos.txtWhatsappPais}
                                    label='Whatsapp'
                                    onChange={(datos) => {
                                        setDatos((s) => ({
                                            ...s,
                                            txtWhatsapp: datos.number,
                                            txtWhatsappCode: datos.code,
                                            txtWhatsappPais: datos.iso,
                                        }));
                                    }}
                                /> */}
                                </div>
                            </div>
                            {(listTipoCarnet.clave == '3') ?
                                <Fragment>
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label
                                                style={{ fontWeight: 600 }}
                                                htmlFor="Puesto"
                                            >
                                                Puesto<span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control border border-dark"
                                                id={`txtPuesto${indice + 1}`}
                                                name="txtPuesto"
                                                type="text"
                                                placeholder="Puesto"
                                                value={datos.txtPuesto ? datos.txtPuesto : ''}
                                                onChange={(e) => {
                                                    e.persist()
                                                    setDatos((s) => ({ ...s, txtPuesto: e.target.value }))
                                                }}
                                            />
                                            <span className={`text-danger txtVaciosItem${indice + 1} txtPuesto${indice + 1}`} style={{ display: 'none' }}>Falta completar Puesto</span>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input border-dark"
                                                    id={`txtEsAnfitrion${indice + 1}`}
                                                    name="txtEsAnfitrion"
                                                    type="checkbox"
                                                    placeholder="Es Anfitrion"
                                                    checked={datos.txtEsAnfitrion ? true : false}
                                                    // value={datos.txtEsAnfitrion? 1: 0}
                                                    // {...(datos.txtEsAnfitrion? checked: '')}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        setDatos((s) => ({ ...s, txtEsAnfitrion: !s.txtEsAnfitrion }))
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="flexCheckDefault" style={{ fontWeight: 600 }}>
                                                    Es Anfitrion<span className="text-danger">*</span>
                                                </label>
                                                <span className={`text-danger txtVaciosItem${indice + 1} txtEsAnfitrion${indice + 1}`} style={{ display: 'none' }}>Falta completar Anfitrion</span>
                                            </div>
                                        </div>
                                    </div> */}
                                </Fragment>
                                : ''}
                        </div>
                        {datos.listTipoHospedaje == '2-0' || datos.listTipoHospedaje == '2-1' ?
                            <Fragment>
                                <div className="row">
                                    <div className="col-12 mt-4 mb-2">
                                        <h5>Asistente 2</h5>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label
                                                style={{ fontWeight: 600 }}
                                                htmlFor="pnombre"
                                            >
                                                Nombre(s)<span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control border border-dark"
                                                type="text"
                                                id={`txtNombreSeg${indice + 1}`}
                                                name="txtNombreSeg"
                                                placeholder="Nombre(s)"
                                                value={datos.txtNombreSeg ? datos.txtNombreSeg : ''}
                                                onChange={(e) => {
                                                    e.persist()
                                                    setDatos((s) => ({ ...s, txtNombreSeg: e.target.value }))
                                                }}
                                            />
                                            <span className={`text-danger txtVaciosItem${indice + 1} txtNombreSeg${indice + 1}`} style={{ display: 'none' }}>Falta completar (Nombre(s))</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label
                                                style={{ fontWeight: 600 }}
                                                htmlFor="papellidos"
                                            >
                                                Apellidos<span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control border border-dark"
                                                id={`txtApellidosSeg${indice + 1}`}
                                                name="txtApellidosSeg"
                                                type="text"
                                                placeholder="Apellidos"
                                                value={datos.txtApellidosSeg ? datos.txtApellidosSeg : ''}
                                                onChange={(e) => {
                                                    e.persist()
                                                    setDatos((s) => ({ ...s, txtApellidosSeg: e.target.value }))
                                                }}
                                            />
                                            <span className={`text-danger txtVaciosItem${indice + 1} txtApellidosSeg${indice + 1}`} style={{ display: 'none' }}>Falta completar Apellidos</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label
                                                style={{ fontWeight: 600 }}
                                                htmlFor="pcorreo"
                                            >
                                                Correo Electrónico
                                            </label>
                                            <input
                                                className="form-control border border-dark"
                                                id={`txtCorreoSeg${indice + 1}`}
                                                name="txtCorreoSeg"
                                                type="text"
                                                // onBlur={onClickBuscarCorreo}
                                                placeholder="Correo Electrónico"
                                                value={datos.txtCorreoSeg ? datos.txtCorreoSeg : ''}
                                                onChange={(e) => {
                                                    e.persist()
                                                    setDatos((s) => ({ ...s, txtCorreoSeg: e.target.value }))
                                                }}
                                            />
                                            <span className={`text-danger txtVaciosItem${indice + 1} txtCorreoSeg${indice + 1}`} style={{ display: 'none' }}>Falta completar Correo Electrónico</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label
                                                style={{ fontWeight: 600 }}
                                                htmlFor="pcorreo"
                                            >
                                                Género
                                            </label>
                                            <Select
                                                className="form-control form-control-md border border-dark"
                                                // tipoValue={1}
                                                data-label="Género"
                                                placeholder="Seleccionar Género"
                                                id={`listGeneroSeg${indice + 1}`}
                                                name="listGeneroSeg"
                                                datos={catalogos.catGenero}
                                                value={datos.listGeneroSeg ? datos.listGeneroSeg : ''}
                                                onChange={({ item }) => {
                                                    setDatos((s) => ({ ...s, listGeneroSeg: item.clave }))
                                                }}
                                            />
                                            <span className={`text-danger txtVaciosItem${indice + 1} listGeneroSeg${indice + 1}`} style={{ display: 'none' }}>Falta completar Género</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label
                                                style={{ fontWeight: 600 }}
                                                htmlFor="pcorreo"
                                            >
                                                Whatsapp
                                            </label>
                                            <div className="input-group mb-2">
                                                <div className="input-group-prepend border">
                                                    <div className="input-group-text"><i className="fa fa-whatsapp" aria-hidden="true"></i></div>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control border border-dark"
                                                    id={`txtWhatsappSeg${indice + 1}`}
                                                    name="txtWhatsappSeg"
                                                    placeholder="Whatsapp"
                                                    value={datos.txtWhatsappSeg ? datos.txtWhatsappSeg : ''}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        setDatos((s) => ({ ...s, txtWhatsappSeg: e.target.value }))
                                                    }}
                                                />
                                            </div>
                                            <span className={`text-danger txtVaciosItem${indice + 1} txtWhatsappSeg${indice + 1}`} style={{ display: 'none' }}>Falta completar Whatsapp</span>
                                        </div>
                                    </div>
                                    {(listTipoCarnet.clave == '3') ?
                                        <Fragment>
                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label
                                                        style={{ fontWeight: 600 }}
                                                        htmlFor="Puesto"
                                                    >
                                                        Puesto
                                                    </label>
                                                    <input
                                                        className="form-control border border-dark"
                                                        id={`txtPuestoSeg${indice + 1}`}
                                                        name="txtPuestoSeg"
                                                        type="text"
                                                        placeholder="Puesto"
                                                        value={datos.txtPuestoSeg ? datos.txtPuestoSeg : ''}
                                                        onChange={(e) => {
                                                            e.persist()
                                                            setDatos((s) => ({ ...s, txtPuestoSeg: e.target.value }))
                                                        }}
                                                    />
                                                    <span className={`text-danger txtVaciosItem${indice + 1} txtPuestoSeg${indice + 1}`} style={{ display: 'none' }}>Falta completar Puesto</span>
                                                </div>
                                            </div> */}
                                            {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input border-dark"
                                                id={`txtEsAnfitrion${indice + 1}`} 
                                                name="txtEsAnfitrion"
                                                type="checkbox"
                                                placeholder="Es Anfitrion"
                                                checked={datos.txtEsAnfitrion? true: false}
                                                // value={datos.txtEsAnfitrion? 1: 0}
                                                // {...(datos.txtEsAnfitrion? checked: '')}
                                                onChange={(e) => {
                                                    e.persist()
                                                    setDatos((s) => ({ ...s, txtEsAnfitrion: !s.txtEsAnfitrion }))
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="flexCheckDefault"  style={{ fontWeight: 600 }}>
                                                Es Anfitrion<span className="text-danger">*</span>
                                            </label>
                                            <span className={`text-danger txtVaciosItem${indice + 1} txtEsAnfitrion${indice + 1}`} style={{display: 'none'}}>Falta completar Anfitrion</span>  
                                        </div>
                                    </div>
                                </div> */}
                                        </Fragment>
                                        : ''}
                                </div>
                            </Fragment>
                            : ''}
                        {/* {datos.listTipoHospedaje == '1-1' || datos.listTipoHospedaje == '2-1' ?
                            <Fragment>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label
                                                style={{ fontWeight: 600 }}
                                                htmlFor="pnombre"
                                            >
                                                Fecha de entrada<span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control border border-dark"
                                                type="date"
                                                id={`txtFechaInicio${indice + 1}`}
                                                name="txtFechaInicio"
                                                placeholder="Nombre(s)"
                                                value={datos.txtFechaInicio ? datos.txtFechaInicio : ''}
                                                onChange={(e) => {
                                                    e.persist()
                                                    setDatos((s) => ({ ...s, txtFechaInicio: e.target.value }))
                                                }}
                                            />
                                            <span className={`text-danger txtVaciosItem${indice + 1} txtFechaInicio${indice + 1}`} style={{ display: 'none' }}>Falta completar (Nombre(s))</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label
                                                style={{ fontWeight: 600 }}
                                                htmlFor="pnombre"
                                            >
                                                Fecha de salida<span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control border border-dark"
                                                type="date"
                                                id={`txtFechaFin${indice + 1}`}
                                                name="txtFechaFin"
                                                placeholder="Nombre(s)"
                                                value={datos.txtFechaFin ? datos.txtFechaFin : ''}
                                                onChange={(e) => {
                                                    e.persist()
                                                    setDatos((s) => ({ ...s, txtFechaFin: e.target.value }))
                                                }}
                                            />
                                            <span className={`text-danger txtVaciosItem${indice + 1} txtFechaFin${indice + 1}`} style={{ display: 'none' }}>Falta completar (Nombre(s))</span>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        : ''} */}
                        <div>
                        {esEditar? 
                        <div className="row mb-3">
                            <div className="col-md-12">
                                <div className="row d-flex justify-content-end">
                                    <div className="col-md-4">
                                        <button type="button" className="btn btn-primary btn-lg btn-block"
                                            onClick={() => onClickEditarCarnet(indice, carnet)}
                                        >Guardar Carnet #{indice + 1}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
}

export default ItemCarnet