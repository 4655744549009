import React,{ useState } from "react"

const Default = (props) => {
    const [value, setValue] = useState('')
    const onChange = (e) => {
        let value = e.target.value
        let item = props.datos.find((s) => s.clave == value)
        setValue(item.clave)
        props.onChange({item, e})
    }

    return (
    <select
        {...props}
        onChange={props.onChange?onChange:null}
        value={props.tipoValue === 1? props.value: (props.value? props.value: value)}
        >
        <option value="" hidden>{props.placeholder?props.placeholder: 'Seleccionar opción'}</option>
        {props.datos?
            props.datos.map((v, i) => (
            <option key={v.clave+'-'+i} value={v.clave}>{v.label}</option>
            ))
        : ''}
    </select>)
}

export default Default